@import "~antd/dist/antd.css";

.MainMenu .ant-layout-sider-zero-width-trigger {
  position: fixed;
  top: 12px;
  left: 0;
}

.MainSection {
  padding: 32px 16px;
}

@media (min-width: 992px) {
  .MainSection {
    padding: 32px 64px 32px 314px;
  }
}

.ant-table-thead .column__text--right .ant-table-header-column {
  margin-right: 9px;
}

.ant-form-explain,
.ant-form-extra {
  color: #f5222d;
}

.weekend {
  background-color: #f0f2f5 !important;
}

.ant-calendar-picker,
.ant-input-number,
.ant-time-picker {
  width: 100%;
}

.ant-form-item-label {
  text-align: left;
}

.alt {
  background-color: #f0f2f5 !important;
}

@media print {
  .no-print {
    display: none;
  }

  .ant-col-xs-24 {
    width: 50%;
  }

  main.MainSection {
    margin-top: 0 !important;
  }
}

.calendar .events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calendar .events .ant-badge-status {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
